import styled from 'styled-components';

import Button from '@Components/atoms/Button';
import Divider from '@Components/atoms/Divider';
import Modal from '@Components/molecules/Modal';

export const Root = styled(Modal)``;

export const StyledButton = styled(Button)`
  margin: 0;
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${(props) => props.theme.space.default_16};
`;
