import React, { ReactNode } from 'react';
import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button';
import Row from '@Components/atoms/Row';
import { PropsWithClassName } from '@Components/helper';
import { Root, StyledButton, StyledDivider } from './ConfirmationModal.styles';
interface Props extends PropsWithClassName {
  children: ReactNode;
  onAccept: () => void;
  acceptText: string;
  acceptModifier?: ButtonModifier;
  onAbort: () => void;
  abortText: string;
  abortModifier?: ButtonModifier;
}
const ConfirmationModal = (props: Props): React.ReactElement => {
  const {
    children,
    onAccept,
    onAbort,
    acceptText,
    abortText,
    acceptModifier = ButtonModifier.Primary,
    abortModifier = ButtonModifier.Secondary,
    className,
    dataTestId
  } = props;
  const testId = dataTestId ? dataTestId : 'ConfirmationModal';
  return <Root onClose={onAbort} minWidth={500} dataTestId={testId + '-root'} className={className} data-sentry-element="Root" data-sentry-component="ConfirmationModal" data-sentry-source-file="ConfirmationModal.tsx">
      {children}
      <StyledDivider data-sentry-element="StyledDivider" data-sentry-source-file="ConfirmationModal.tsx" />
      <Row noGutters xsAround data-sentry-element="Row" data-sentry-source-file="ConfirmationModal.tsx">
        <StyledButton dataTestId={testId + '-button-accept'} modifier={acceptModifier} type={ButtonType.Button} onClick={onAccept} data-sentry-element="StyledButton" data-sentry-source-file="ConfirmationModal.tsx">
          {acceptText}
        </StyledButton>
        <StyledButton dataTestId={testId + '-button-abort'} modifier={abortModifier} type={ButtonType.Button} onClick={onAbort} data-sentry-element="StyledButton" data-sentry-source-file="ConfirmationModal.tsx">
          {abortText}
        </StyledButton>
      </Row>
    </Root>;
};
export default ConfirmationModal;